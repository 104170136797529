import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import { Link } from 'react-router-dom';

const useStyles = makeStyles( theme => ({
    root: {
        background: 'linear-gradient(158.81deg, #009FFD 0%, #2A2A72 100%)',
        padding: 1,
        boxSizing: 'border-box',
        width: 90,
        height: 40,
        borderRadius: 41,
        marginLeft: 30
    },
    btnStyle: {
        background: '#F6F6F6',
        width: '100%',
        height: '100%',
        textTransform: 'none',
        color: theme.palette.primary.main,
        borderRadius: 41,
        '&:hover': {
            background: '#F6F6F6',
            boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.25)'
        }
    }
}));

function LoginButton({ children }) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Button
                endIcon={<LogoutIcon color="primary" />}
                className={classes.btnStyle}
                component={Link}
                to="/login"
            >
                {children}
            </Button>
        </div>
    )
}

export default LoginButton;