import React from 'react';
import { makeStyles, Grid, Typography, Avatar } from '@material-ui/core';
import { CustomButtonOne } from '@components';
import Rating from '@material-ui/lab/Rating';
import { Link } from 'react-router-dom';


import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';

import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';

const useStyles = makeStyles( theme => ({
    root: {
        boxShadow: '0px 15px 30px rgba(0, 0, 0, 0.15)',
        background: '#fff',
        borderRadius: 8,
        width: '100%',
        padding: '30px 14px'
    },
    userImage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    ratingStyle: {
        color: theme.palette.primary.main
    },
    iconsWrapper: {
        background: theme.palette.primary.main,
        width: 40,
        height: 40,
        borderRadius: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    reviewChip: {
        background: theme.palette.primary.main,
        padding: '8px 12px',
        color: '#fff',
        borderRadius: 20,
        marginTop: 10,
        marginRight: 10
    }
}));

function MyProfileCard({ 
    profile,
    stats
}) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.userImage}>
                <Avatar 
                    src={profile && profile?.user_detail[0]?.avatar}
                    alt={profile && profile?.first_name}
                    style={{
                        width: 155,
                        height: 155
                    }}
                />
            </div>
            <Typography variant="body1" color="textPrimary" style={{ margin: '20px 0px', textAlign: 'center' }}>
                {profile && profile?.first_name}
            </Typography>
            {profile && profile?.user_detail[0]?.state && (
                <Grid container alignItems="center" justify="center">
                    <LocationOnOutlinedIcon color="primary" style={{ fontSize: 18 }} />
                    <Typography variant="body1" color="textPrimary" style={{ marginLeft: 5 }}>
                        {profile && profile?.user_detail[0]?.state}, {profile && profile?.user_detail[0]?.city}
                    </Typography> 
                </Grid>
            )}

            <Grid container alignItems="center" justify="center" style={{ margin: '20px 0px' }}>
                <CustomButtonOne
                    component={Link}
                    to="/account-settings"
                >
                    Edit profile
                </CustomButtonOne>
            </Grid>

            <div
                style={{
                    width: '100%',
                    height: 1,
                    background: '#EDEDED',
                    margin: '10px 0'
                }}
            />
            
            <Grid container alignItems="center" justify="space-between" style={{ margin: '20px 0px' }}>
                <Typography variant="body1" color="textPrimary">
                    Participations
                </Typography> 
                <Typography variant="body1" color="textPrimary">
                    {stats && stats?.participation || 0}
                </Typography> 
            </Grid>
            
            <Grid container alignItems="center" justify="space-between" style={{ margin: '20px 0px' }}>
                <Typography variant="body1" color="textPrimary">
                    Victories
                </Typography> 
                <Typography variant="body1" color="textPrimary">
                    {stats && stats?.victories || 0}
                </Typography> 
            </Grid>


            <div
                style={{
                    width: '100%',
                    height: 1,
                    background: '#EDEDED',
                    margin: '10px 0'
                }}
            />

            {/* <Grid container alignItems="center" justify="space-between" style={{ margin: '20px 0px' }}>
                <Typography variant="body1" color="textPrimary">
                    Float Points
                </Typography> 
                <Typography variant="body1" color="textPrimary">
                    {stats && stats?.float_points || 0}
                </Typography> 
            </Grid> */}

            <Grid container alignItems="center" justify="space-between" style={{ margin: '20px 0px' }}>
                <Typography variant="body1" color="textPrimary">
                    Float Coins
                </Typography> 
                <Typography variant="body1" color="textPrimary">
                    {stats && stats?.float_bucks || 0}
                </Typography> 
            </Grid>

            <div
                style={{
                    width: '100%',
                    height: 1,
                    background: '#EDEDED',
                    margin: '10px 0'
                }}
            />
            
            <Grid container alignItems="center" style={{ margin: '20px 0px' }}>
                <FacebookIcon color="primary" />
                <InstagramIcon color="primary" style={{ marginLeft: 10 }} />
                <TwitterIcon color="primary" style={{ marginLeft: 10 }} />
            </Grid>
            
            <div
                style={{
                    width: '100%',
                    height: 1,
                    background: '#EDEDED',
                    margin: '10px 0'
                }}
            />

            <Typography variant="body1" color="textPrimary">
                About Me
            </Typography> 
            <Typography variant="body2" color="textPrimary" style={{ marginTop: 10 }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie id tempus in sem arcu, massa. Vitae pulvinar pretium cras id volutpat purus. Orci consequat est, laoreet quam accumsan, sed placerat nec tristique. Posuere dictumst volutpat malesuada venenatis. Convallis ut ac, urna, sodales a ullamcorper et.
            </Typography> 
        </div>
    )
}

export default MyProfileCard;