import React from "react";
import { makeStyles, TextField, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // height: 214,
    background: "#fff",
    borderRadius: 8,
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.15)",
    border: `1px solid ${theme.palette.primary.main}`,
    padding: "15px",
    "& .MuiInputBase-input": {
      color: theme.palette.text.primary,
      "&::placeholder": {
        ...theme.typography.subtitle1,
        color: theme.palette.text.primary,
        opacity: 0.5,
      },
    },
  },
}));

function TextArea({ label, ...rest }) {
  const classes = useStyles();
  return (
    <div>
      {label && (
        <Typography
          variant="subtitle1"
          color="textPrimary"
          style={{ marginBottom: 10 }}
        >
          {label}
        </Typography>
      )}
      {/* <TextField
                {...rest}
                multiline
                rows={4}
                autoComplete="off"
                variant="outlined"
                className={classes.root}
            /> */}
      <textarea {...rest} className={classes.root} />
    </div>
  );
}

export default TextArea;
