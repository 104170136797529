import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import { Link, withRouter } from "react-router-dom";
import { CustomButtonThree, CustomButtonTwo } from "@components";
import { Typography } from "@material-ui/core";
import { connect } from "react-redux";

import { closeModal } from "../modalActions";
import { setRedirectUrl, clearRedirectUrl } from "@store/actions/addCart";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function LoginPopup(props) {
  const successHandler = () => {
    if (props.modalProps?.from === "cart") {
      props.setRedirectUrl("/cart");
      props.closeModal();
    }
    if (props.modalProps?.from === "product-details") {
      props.setRedirectUrl(props.modalProps?.url);
      props.closeModal();
    } else {
      props.clearRedirectUrl();
      props.closeModal();
    }
  };

  return (
    <div>
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography color="textPrimary" variant="h4">
              You are not logged in.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButtonThree
            onClick={props.closeModal}
            style={{
              width: 130,
              height: 35,
              fontSize: 14,
            }}
          >
            Cancel
          </CustomButtonThree>
          <CustomButtonTwo
            onClick={successHandler}
            component={Link}
            to="/login"
            style={{
              width: 130,
              height: 35,
              fontSize: 14,
            }}
          >
            Log in
          </CustomButtonTwo>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  modalProps: state.modal.modalProps,
});

export default withRouter(
  connect(mapStateToProps, {
    closeModal,
    setRedirectUrl,
    clearRedirectUrl,
  })(LoginPopup)
);
