import React from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";

import { CustomButtonOne } from "@components";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
    boxShadow: "0px 15px 30px rgba(0, 0, 0, 0.15)",
    width: "100%",
    borderRadius: 8,
    height: "453px",
    position: "relative",
    [theme.breakpoints.down("500")]: {
      width: "100%",
    },
  },
  imgStyle: {
    width: "100%",
    objectFit: "fill",
    borderRadius: "8px 8px 0px 0px",
    height: "100%",
  },
  actionArea: {
    padding: "0px 15px",
    boxSizing: "border-box",
    position: "absolute",
    bottom: 10,
    left: 0,
    width: "100%",
  },
  fixedWidth: {
    width: "361px",
  },

  checkMorefixedWidth: {
    width: "353px",
  },
}));

function CardTwo({ item, isFixedWidth, isCheckMore }) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <motion.div
      className={clsx([
        classes.root,
        isFixedWidth && classes.fixedWidth,
        isCheckMore && classes.checkMorefixedWidth,
      ])}
      initial={{
        opacity: 0.7,
        transform: "scale(0.7)",
      }}
      animate={{
        opacity: 1,
        transform: "scale(1)",
      }}
      transition={{
        type: "tween",
      }}
    >
      <div
        style={{
          height: "340px",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
        <img
          src={
            item?.images.length > 0
              ? item?.images[0].image
              : "http://placehold.it/361x350"
          }
          alt={item?.name}
          className={classes.imgStyle}
        />
      </div>
      <div
        style={{
          padding: "0px 15px",
          boxSizing: "border-box",
          marginTop: 5,
          height: 40,
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Typography variant="body2" color="textPrimary">
          {item?.name}
          Thank you. Was scratching my head for a while. Now I am scratching
          more wondering why would I ever Thank you. Was scratching my head for
          a while. Now I am scratching more wondering why would I ever
        </Typography>
        {item?.name?.length > 100 && (
          <span
            style={{
              position: "absolute",
              bottom: 2,
              right: 25,
              width: "50px",
              background: "#fff",
            }}
          >
            ...
          </span>
        )}
      </div>
      <div className={classes.actionArea}>
        <Grid container alignItems="center" justify="space-between">
          <Typography variant="body2" color="textPrimary">
            Price:{" "}
            <strong style={{ color: theme.palette.primary.main }}>
              ${item?.price}
            </strong>
          </Typography>

          <CustomButtonOne
            style={{
              width: 114,
              height: 30,
            }}
            component={Link}
            to={`/product-details/${item?.id}`}
          >
            Buy now
          </CustomButtonOne>
        </Grid>
      </div>
    </motion.div>
  );
}

export default CardTwo;
