import React from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import { Slide } from "react-slideshow-image";
import { CustomButtonOne } from "@components";
import "react-slideshow-image/dist/styles.css";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "480px",
    borderRadius: 8,
  },
  image_container: {
    height: "450px",
    backgroundSize: "contain",
    backgroundPosition: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    backgroundRepeat: "no-repeat",
  },
  ngos_image_container: {
    height: "450px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    position: "relative",
  },
  imgStyle: {
    width: "100%",
    height: "100%",
    filter: "brightness(50%)",
    position: "absolute",
    top: 0,
    left: 0,
  },
}));

function FloatSlider({ data, type }) {
  const classes = useStyles();
  const [filteredData, setData] = React.useState([]);
  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    arrows: true,
  };
  React.useEffect(() => {
    const d = data?.filter((item) => item?.is_feature === false);
    setData(d);
  }, []);

  return (
    <div className={classes.root}>
      {type === "ngos" && (
        <Slide {...properties}>
          {data.map((img) => (
            <div className="each-slide" key={img.id}>
              <div className={classes.ngos_image_container}>
                <img
                  src={img?.image || "http://placehold.it/900x460"}
                  alt={img?.name}
                  className={classes.imgStyle}
                />
                <Grid container style={{ zIndex: 9 }}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{
                      padding: "0px 0px 0px 50px",
                    }}
                  >
                    <Typography variant="h2" style={{ color: "#fff" }}>
                      {img?.name}
                    </Typography>
                    <br />
                    <CustomButtonOne>Details</CustomButtonOne>
                  </Grid>

                  <Grid item xs={12} md={6}></Grid>
                </Grid>
              </div>
            </div>
          ))}
        </Slide>
      )}

      {type === "float" && (
        <Slide {...properties}>
          {filteredData.map((img) => (
            <div className="each-slide" key={img.id}>
              <div
                style={{ backgroundImage: `url(${img.image})` }}
                className={classes.image_container}
              >
                {/* <Typography variant="h1" color="secondary">{img.caption}</Typography> */}
              </div>
            </div>
          ))}
        </Slide>
      )}
    </div>
  );
}

export default FloatSlider;
