import React from 'react';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import { CustomButtonOne, CircularProgress } from '@components';

const useStyles = makeStyles( theme => ({
    root: {
        background: '#fff',
        boxShadow: '0px 15px 30px rgba(0, 0, 0, 0.15)',
        width: '100%',
        borderRadius: 8,
        height: '400px',
        position: 'relative'
    },
    imgStyle: {
        width: '100%',
        objectFit: 'fill',
        borderRadius: '8px 8px 0px 0px',
        height: '320px'
    },
    leftStyle: {
        color: theme.palette.text.primary
    },
    actionArea: {
        padding: '0px 15px', 
        boxSizing: 'border-box',
        position: 'absolute',
        bottom: 10,
        left: 0,
        width: '100%'
    },
    greetings: {
        color: theme.palette.primary.main
    }
}));

function WonCard({ item }) {
    const classes = useStyles();
    return (
        <motion.div 
            className={classes.root}
            initial={{
                opacity: 0.7,
                transform: 'scale(0.7)'
            }}
            animate={{
                opacity: 1,
                transform: 'scale(1)'
            }}
            transition={{
                type: 'tween'
            }}
        >
            <img
                src={item?.images.length > 0 ? item?.images[0].image : "http://placehold.it/361x350"} 
                alt={item?.name}
                className={classes.imgStyle}
            />
            <div style={{ padding: '0px 15px', boxSizing: 'border-box', marginTop: 5 }}>
                <Typography variant="body2" color="textPrimary">
                    <strong className={classes.greetings}>Congratulations!</strong> You won an {item?.name} by pitching ${item?.joining_float_price || item?.price}
                </Typography>
            </div>
        </motion.div>
    )
}

export default WonCard;