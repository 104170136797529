import React from "react";
import "./App.css";
import { Grid, Typography } from "@material-ui/core";
import { Switch, Route, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import ReduxToastr from "react-redux-toastr";
import ModalManager from "@modals/ModalManager";

const HomePage = React.lazy(() => import("./UI/pages/home"));
const Header = React.lazy(() => import("./UI/lauout/header/NavBar"));
const Footer = React.lazy(() => import("./UI/lauout/footer/Footer"));
const FloatDetails = React.lazy(() => import("./UI/pages/float/FloatDetails"));
const BuyProduct = React.lazy(() => import("./UI/pages/product/BuyProduct"));
const MyCart = React.lazy(() => import("./UI/pages/Cart/MyCart"));
const Winners = React.lazy(() => import("./UI/pages/winners/Winners"));
const WinnerDetails = React.lazy(() => import("./UI/pages/WinnerDetails"));
const HowItWorks = React.lazy(() => import("./UI/pages/HowItWorks"));
const Search = React.lazy(() => import("./UI/pages/Search"));
const FloatList = React.lazy(() => import("./UI/pages/FloatList"));
const MyProfile = React.lazy(() => import("./UI/pages/MyProfile"));
const PublicProfile = React.lazy(() => import("./UI/pages/PublicProfile"));
const AccountSettings = React.lazy(() => import("./UI/pages/AccountSettings"));
const About = React.lazy(() => import("./UI/pages/AboutUs/About"));
const PressRelease = React.lazy(() => import("./UI/pages/PressRelease"));
const PrivacyPolicy = React.lazy(() => import("./UI/pages/PrivacyPolicy"));
const TermsConditions = React.lazy(() => import("./UI/pages/TermsConditions"));
const ContactUs = React.lazy(() => import("./UI/pages/ContactUs"));
const Login = React.lazy(() => import("./UI/pages/Auth/Login"));
const Register = React.lazy(() => import("./UI/pages/Auth/Register"));
const LoginWithSocial = React.lazy(() =>
  import("./UI/pages/Auth/LoginWithSocial")
);
const ResetEmailSend = React.lazy(() =>
  import("./UI/pages/Auth/ResetEmailSend")
);
const ResetPassword = React.lazy(() => import("./UI/pages/Auth/ResetPassword"));

function App() {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.6,
    },
    in: {
      opacity: 1,
      scale: 1,
    },
    out: {
      opacity: 0,
      scale: 1.01,
    },
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.8,
  };
  return (
    <div>
      <ModalManager />
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        getState={(state) => state.toastr} // This is the default
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        // progressBar
        closeOnToastrClick
      />
      <AnimatePresence>
        <React.Suspense
          fallback={
            <Grid
              container
              alignItems="center"
              justify="center"
              style={{ height: "100vh" }}
            >
              <Typography variant="h6" color="primary">
                Please wait...
              </Typography>
            </Grid>
          }
        >
          <Switch>
            {/* auth routes */}
            <Route
              path={[
                "/login",
                "/register",
                "/social-auth",
                "/reset-email",
                "/reset/:uid/:token",
              ]}
            >
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}
                >
                  <Route path="/login" component={Login} />
                  <Route path="/register" component={Register} />
                  <Route path="/social-auth" component={LoginWithSocial} />
                  <Route path="/reset-email" component={ResetEmailSend} />
                  <Route path="/reset/:uid/:token" component={ResetPassword} />
                </motion.div>
              </Switch>
            </Route>

            {/* main app routes */}
            <Route
              path={[
                "/",
                "/float-details/:floatID",
                "/product-details/:productID",
                "/cart",
                "/winners",
                "/winner-details/:id",
                "/how-it-works",
                "/search",
                "/float-listing",
                "/my-profile",
                "/public-profile/:id",
                "/account-settings",
                "/checkout",
                "/about",
                "/press-release",
                "/privacy-policy",
                "/terms-conditions",
                "/contact-us",
              ]}
            >
              <Header />
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}
                >
                  <Route exact path="/" component={HomePage} />
                  <Route
                    path="/float-details/:floatID"
                    component={FloatDetails}
                  />
                  <Route
                    path="/product-details/:productID"
                    component={BuyProduct}
                  />
                  <Route path="/cart" component={MyCart} />
                  <Route path="/winners" component={Winners} />
                  <Route path="/winner-details/:id" component={WinnerDetails} />
                  <Route path="/how-it-works" component={HowItWorks} />
                  <Route path="/search" component={Search} />
                  <Route path="/float-listing" component={FloatList} />
                  <Route path="/my-profile" component={MyProfile} />
                  <Route path="/public-profile/:id" component={PublicProfile} />
                  <Route path="/account-settings" component={AccountSettings} />
                  <Route
                    path="/checkout"
                    component={() => <h1>checkout page</h1>}
                  />
                  <Route path="/about" component={About} />
                  <Route path="/press-release" component={PressRelease} />
                  <Route path="/privacy-policy" component={PrivacyPolicy} />
                  <Route path="/terms-conditions" component={TermsConditions} />
                  <Route path="/contact-us" component={ContactUs} />
                </motion.div>
              </Switch>
              <Footer />
            </Route>
          </Switch>
        </React.Suspense>
      </AnimatePresence>
    </div>
  );
}

export default App;
