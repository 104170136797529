import { CustomButtonOne } from "@components";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "460px",
    borderRadius: 8,
    marginBottom: 30,
  },
  image_container: {
    height: "460px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    position: "relative",
  },
  imgStyle: {
    width: "100%",
    height: "100%",
    // filter: 'brightness(50%)',
    position: "absolute",
    top: 0,
    left: 0,
  },
}));

function ImageFrame({ caption, data }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.image_container}>
        <img
          src={data?.image || "http://placehold.it/900x460"}
          alt={data?.name}
          className={classes.imgStyle}
        />
        {caption && (
          <Grid container style={{ zIndex: 9 }}>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                padding: "0px 0px 0px 50px",
              }}
            >
              <Typography variant="h2" style={{ color: "#fff" }}>
                {data?.name}
              </Typography>
              <br />
              <CustomButtonOne>Details</CustomButtonOne>
            </Grid>

            <Grid item xs={12} md={6}></Grid>
          </Grid>
        )}
      </div>
    </div>
  );
}

export default ImageFrame;
